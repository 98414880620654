<template>
	<v-card class="mb-2">
		<div class="d-flex flex-no-wrap justify-space-between pl-3 pr-3" >
			<v-container>
			<v-layout row wrap>
				<v-flex xs12 sm4>
				<v-skeleton-loader type="image" class="skeleton-image"></v-skeleton-loader>
				</v-flex>
				<v-flex xs12 sm8 class="pl-2">
				<v-flex style="flex-direction:column!important">
					<v-flex style="min-height:200px">
						<v-skeleton-loader type="heading" height="32" class="skeleton-title"></v-skeleton-loader>
						<v-skeleton-loader v-if="fecha" type="text" class="skeleton-fecha"></v-skeleton-loader>
						<div class="ma-1">
							<v-skeleton-loader type="text" class="skeleton-text" v-for="item in lineas" :key="'skl-' + item"></v-skeleton-loader>
							<!-- <v-skeleton-loader type="text" class="skeleton-text"></v-skeleton-loader>
							<v-skeleton-loader type="text" class="skeleton-text"></v-skeleton-loader>
							<v-skeleton-loader type="text" class="skeleton-text"></v-skeleton-loader>
							<v-skeleton-loader type="text" class="skeleton-text"></v-skeleton-loader>
							<v-skeleton-loader type="text" class="skeleton-text"></v-skeleton-loader>
							<v-skeleton-loader type="text" class="skeleton-text"></v-skeleton-loader> -->
						</div>
					</v-flex>
					<v-card-actions class="pl-2" style="justify-content:space-between;padding:0px;margin-top: 10px;">
						<v-skeleton-loader type="button" class="skeleton-button" ></v-skeleton-loader>
					</v-card-actions>
				</v-flex>
				</v-flex>
			</v-layout>
			</v-container>
		</div>
		<v-skeleton-loader v-if="tag" type="chip" class="tag-item skeleton-tag"></v-skeleton-loader>
	</v-card>
</template>

<script>
export default {
  data () {
    return {}
  },
  props: ['tag','lineas','fecha'],
  methods:{
}
};
</script>
<style>
.skeleton-image > .v-skeleton-loader__bone{
  min-height: 250px!important;
}

.skeleton-title > .v-skeleton-loader__bone{
  height: 24px!important;
  line-height: 32px!important;
}

.skeleton-fecha > .v-skeleton-loader__bone{
  height: 16px;
  width: 150px;
  margin-top: 4px;
  margin-bottom: 4px;
}

.skeleton-text > .v-skeleton-loader__bone{
  height: 16px;
  margin-top: 2px;
  margin-bottom: 2px;
}

.skeleton-button > .v-skeleton-loader__bone{
  width: 94px;
}

.skeleton-tag,.skeleton-tag > .v-skeleton-loader__bone{
  border-radius: 0px 0px 0px 5px!important;
  width: 125px;
}
</style>